@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@keyframes fadeIn {
  0% {
      opacity: 0;
      
    }
   
    100% {
      opacity: 1;
      
    }
}

.left-half {
  float: left;
 
  margin: 0;
  
  
}
.right-half {
  float: right;
  width: 100%;
}



@keyframes floatingMan {
  0% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
  33% {/*transform: translateX(15%);*/ filter: drop-shadow(0px 0px 15px black);}
  50% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
  66% {/*transform: translateX(-15%);*/ filter: drop-shadow(0px 0px 15px black);}
  100% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
}

@keyframes spaceship {
  0% {transform: translateX(0%);}
  50% {transform: translateX(60%);}
  100% {transform: translateX(0%);}
}

@keyframes scaleIn {
  0% {
    transform: scale(.5, .5);
    opacity: .5;
    background-color: black;
  }
  50% {
    transform: scale(1.5, 1.5);
    opacity: 1;
    background-color: black;
  }
  100% {
    transform: scale(.5, .5);
    opacity: .5;
    background-color: black;
  }
}


.slfy-store * {
  color: red !important;
}

iframe {
  width: 100%;
}

.slfy-store .product-card {
  width: 50% !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus {
  border: 3px solid black;
  border-radius: 0;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
  border: 3px solid black;
  border-radius: 0;
}

* {
  font-family: 'Archivo Black', sans-serif;
  
}

a {
  font-size: 2.25em;
  text-decoration: none;
  color: transparent;
    -webkit-text-stroke-width: .08em;
    -webkit-text-stroke-color: black;
}
a.hover {
  text-decoration: none;
  color: transparent;
    -webkit-text-stroke-width: .1em;
    -webkit-text-stroke-color: black;
}

a.active {
  text-decoration: none;
}

a:hover { color: black; }

button:hover {
  color: black;
}



h1 {
    font-size: 2em;
}

body {
  /*background-image: url("media/day_background.jpeg");
  background-size: 100vw;*/

/*background: linear-gradient(180deg, rgba(255,129,142,1) 0%, rgba(255,198,0,1) 100%);*/
background: linear-gradient(180deg, rgba(31,31,31,1) 0%, rgba(252,252,252,1) 100%);
background-size: cover;

background-repeat: no-repeat;
background-attachment: scroll;
}




.merch {
  width: 30%;
}

.bracelet {
  width: 80%;
}

.merchText {
  font-size: 2em;
  margin: 0;
  padding: 0;
}

.merchText2 {
  font-size: .8em;
  margin: 0;
  padding: 0;
}

#container {
  overflow: visible !important;
}


.pageContainer {
  width: auto;
  padding: 0;
  margin: 0;
  max-width: 80%;
  margin: 0 auto;
  min-height: 100vh;
  height: auto;
  -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
    animation: 3s ease 0s normal forwards 1 fadeIn;
    opacity: 0;
  
}

.logo {
  width: 25%;
}

.cover {
  width: 60%;
  border: .5em solid black;
}

.image-gallery-slides {
  border: .5em solid black;
  background-color: black;
}

.asset {
  width: 80%;
}
.smileAsset {
  width: 80%;
  margin-right: 10px;
}

.image-gallery-slide {
  opacity: 0;
  transition: opacity 2s ease-out !important;
}
.image-gallery-slide.center {
  
  opacity: 1;
}

.shows {
  width: 90%;
}

.containBorderAsset {
  width: 80%;
}

.borderAsset {
  width: 40%;
  float: right;
  transform: translate(-0%, 15%);
}

.containBorderAsset2 {
  margin-top: 30%;
  width: 80%;
}

.borderAsset2 {
  width: 60%;
  float: left;
  animation-name: spaceship;
  animation-duration: 15s;
  animation-iteration-count: infinite;
}


.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin: 0;
}

.socialContainer {
  margin-top: 1em;
  width: 40%;
}

.social {
  display: inline-block;
  width: 25%;
  margin: .5em;
  
}

p {
  font-size: x-large;
  
  color: black;
}

.mailingList {
  width: 40%;
  font-size: medium;
  padding-top: .5em;
  padding-bottom: .5em;
  height: auto;
  color: black;
  border: .3em solid black;
  background-color: transparent;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: .8em;
  padding-left: 1em;
}

button {
  width: auto;
  font-size: medium;
  padding-top: .5em;
  padding-bottom: .5em;
  height: auto;
  border: .2em solid black;
  background-color: transparent;
  

  padding-left: 1em;
  padding-right: 1em;
  color: transparent;
    

}
 

#container {
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.circle {
  border-radius: 50%;
  background-color: black;
  width: 225px;
  height: 225px;
  position: absolute;
  opacity: 0;
  animation: scaleIn 10s infinite cubic-bezier(.36, .11, .89, .32);
}

.item {
  width: 80%;
  z-index: 100;
  padding: 5px;
}

.item img {
  width: 80%;
  height: auto;
  visibility: hidden;
}



.floatingMan {
  width: 50%;
  animation-name: floatingMan;
  animation-duration: 16s;
  animation-iteration-count: infinite;
  


}

.left {
  width: 100%;
  margin-top: 1em;
  float: left;
  text-align: left;
}

.leftLast {
  width: 100%;
  margin-top: 1em;
  float: left;
  text-align: left;
  margin-bottom: 2em;
}

.footer {
  
    
    margin-bottom: 1em;
    
 
}

.right {
  width: 100%;
  margin-top: 1em;
  float: right;
  text-align: right;
}



.header {
  font-size: 2em;
  color: black;

}

.text {
  font-size: 1.5em;
  color: black;
  font-weight: normal;
}

.subtext {
  font-size: .8em;
  color: black;
}


.submitBtn {
  width: auto;
  font-size: 1em;
  color: black;
  background-color: transparent;
  border: none;
}
p {
  font-size: 1.1em;
  color: black;
}

.Collapsible {
  width: 40%;
}

@media only screen and (min-width: 0px) and (max-width: 800px)
{

  
  .Collapsible {
      width: 80%;
  }
  button {
    width: auto;
    font-size: medium;
    padding-top: .5em;
    padding-bottom: .5em;
    height: auto;
    border: .2em solid black;
    background-color: transparent;
    
  
    padding-left: .5em;
    padding-right: .5em;
    color: transparent;
      
  
  }

  a {
    font-size: 1.5em;
    text-decoration: none;
    color: transparent;
      -webkit-text-stroke-width: .08em;
      -webkit-text-stroke-color: black;
  }
  a.hover {
    text-decoration: none;
    color: transparent;
      -webkit-text-stroke-width: .1em;
      -webkit-text-stroke-color: black;
  }
  
  .merch { 
    width: 70%;
  }

  .merchText {
    font-size: 1.5em;
    margin: 0;
    padding: 0;
  }
  

  .pageContainer {
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    height: auto;
    -webkit-animation: 3s ease 0s normal forwards 1 fadeIn;
      animation: 3s ease 0s normal forwards 1 fadeIn;
    
  }
  

  @keyframes floatingMan {
    0% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
    33% {/*transform: translateX(15%);*/ filter: drop-shadow(0px 0px 100px black);}
    50% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
    66% {/*transform: translateX(-15%);*/ filter: drop-shadow(0px 0px 100px black);}
    100% {/*transform: translateX(0%);*/ filter: drop-shadow(0px 0px 0px black);}
  }

  .floatingMan {
    width: 70%;
    animation-name: floatingMan;
    animation-duration: 16s;
    animation-iteration-count: infinite;
    
  
  
  }


  .circle {
    border-radius: 50%;
    background-color: black;
    width: 150px;
    height: 150px;
    position: absolute;
    opacity: 0;
    animation: scaleIn 10s infinite cubic-bezier(.36, .11, .89, .32);
  }

  .header {
    font-size: 2em;
  }

  .logo {
    width: 50%;
  }
  
  .cover {
    width: 80%;
    border: .5em solid black;
  }
  

  .mailingList {
    width: 60%;
  }

  
  .pageContainer {
    width: 95%;
  }
  .social {
    display: inline-block;
    width: 35%;
    margin: .1em;
    
  }

  .socialContainer {
    margin-top: .5em;
    width: 40%;
  }

  p {
    font-size: 1em;
    color: black;
  }

  .submitBtn {
    font-size: 1em;
    color: black;
    background-color: transparent;
    border: none;
  }
  
  .header {
    font-size: 2.25em;
    color: black;

  }

  .text {
    font-size: 1.5em;
    color: black;
    font-weight: normal;
  }

  .subtext {
    font-size: .8em;
    color: black;
  }

}
