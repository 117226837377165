@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');


// The main container element
$img: url('../src/media/arrow.png');


* {
  font-family: 'Archivo Black', sans-serif;
}
//The content within the collaspable area
.Collapsible__contentInner {


  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  font-size: 3em;
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border: .3em solid white;
  padding: 10px;
  background: transparent;




  &:after {
    content: '^';
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(90deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}


.sos-trigger {
  color: transparent;
  -webkit-text-stroke-width: .08em;
    -webkit-text-stroke-color: black;
  background-color: transparent;
  border: .2em solid black;
}


.sos-content {
  
}

@media only screen and (max-width: 800px)
{
  .Collapsible__contentInner {
    
 
    border-top: 0;
  
    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    font-size: 1.5em;
    display: block;
    font-weight: 400;
    text-decoration: none;

    position: relative;
    border: .3em solid black;
    padding: 10px;

  
  
    &:after {
      content: '>';
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      transition: transform 300ms;
    }
  
    &.is-open {
      &:after {
        transform: rotateZ(90deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }

 
  
}